import { ReactComponent as UiCreativeDesignIcon } from "../lib/icons/Curve.svg";
import { ReactComponent as DesktopApplicationIcon } from "../lib/icons/InformationService.svg";
import { ReactComponent as WebDevelopmentIcon } from "../lib/icons/Monitor.svg";
import { ReactComponent as WireframingServiceIcon } from "../lib/icons/WebProgramming.svg";
import { ReactComponent as StrategyIcon } from "../lib/icons/Strategy.svg";
import { ReactComponent as TranslationIcon } from "../lib/icons/Translation.svg";

export const services = [
  // {
  //   id: 1,
  //   bgColor: "bg-accent",
  //   titleColor: "text-white",
  //   descColor: "text-white",
  //   servIcon: (
  //     <UiCreativeDesignIcon className="h-[4.25rem] w-[4.25rem] fill-white"></UiCreativeDesignIcon>
  //   ),
  //   servTitle: "Front-End Develover",
  //   servDesc:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod labore.",
  // },
  {
    id: 2,
    bgColor: "bg-[#f0e1d4]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <WebDevelopmentIcon className="h-[4.25rem] w-[4.25rem] fill-accent"></WebDevelopmentIcon>
    ),
    servTitle: "Front-End Develover",
    servDesc:
      "Frontend Developer with expertise in the React ecosystem. Experienced in managing application state with Redux, ensuring clean and structured code with TypeScript, and designing attractive displays with Tailwind CSS. Deep understanding of modern web development principles.",
  },
  {
    id: 3,
    bgColor: "bg-[#d4e4f0]",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <WireframingServiceIcon className="h-[4.25rem] w-[4.25rem] fill-accent"></WireframingServiceIcon>
    ),
    servTitle: "Back-End Developer",
    servDesc:
      "Backend Developer experienced in building efficient, scalable, and secure backend systems using NestJS. I have a deep understanding of modular architecture, RESTful APIs, and database integration, as well as the ability to manage projects from planning to implementation.",
  },
  {
    id: 4,
    bgColor: "bg-gray",
    titleColor: "text-accent2",
    descColor: "text-desc2",
    servIcon: (
      <DesktopApplicationIcon className="h-[4.25rem] w-[4.25rem] fill-accent"></DesktopApplicationIcon>
    ),
    servTitle: "CMS Strapi",
    servDesc:
      "With a flexible API-first approach, I help businesses deliver content management solutions that are efficient, fast, and can be integrated with multiple platforms.",
  },
  // {
  //   id: 5,
  //   bgColor: "bg-[#f0fbfe]",
  //   titleColor: "text-accent2",
  //   descColor: "text-desc2",
  //   servIcon: (
  //     <StrategyIcon className="h-[4.25rem] w-[4.25rem] fill-accent"></StrategyIcon>
  //   ),
  //   servTitle: "Strategy & Research",
  //   servDesc:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod labore.",
  // },
  // {
  //   id: 6,
  //   bgColor: "bg-[#fef4f0]",
  //   titleColor: "text-accent2",
  //   descColor: "text-desc2",
  //   servIcon: (
  //     <TranslationIcon className="h-[4.25rem] w-[4.25rem] fill-accent"></TranslationIcon>
  //   ),
  //   servTitle: "Branding & Strategy",
  //   servDesc:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod labore.",
  // },
];
