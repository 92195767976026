export const education = [
  {
    eduDate: "2021 - Now",
    eduTitle: "Sistem Informasi",
    eduIns: " Universitas Bina Sarana Informatika",
    eduDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  // {
  //   eduDate: "2013 - 2015",
  //   eduTitle: "Honours of UI/UX",
  //   eduIns: "Cambridge University",
  //   eduDesc:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  // },
  // {
  //   eduDate: "2008 - 2012",
  //   eduTitle: "Bachelor of Science",
  //   eduIns: "TM Primary School",
  //   eduDesc:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  // },
  // {
  //   eduDate: "2005 - 2008",
  //   eduTitle: "School Certificate",
  //   eduIns: "TM Primary School",
  //   eduDesc:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  // },
];
