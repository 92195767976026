import { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendToWhatsApp = (openWhatsApp = true) => {
    const { name, email, message } = formData;
    const whatsappNumber = "6285162677246";
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      `Hello! My name is ${name}. My email is ${email}. Here is my message: ${message}`
    )}`;

    if (openWhatsApp) {
      // Membuka WhatsApp dengan data yang diisi
      window.open(whatsappURL, "_blank");
    } else {
      // Mengirim pesan ke WhatsApp tanpa membuka aplikasi
      alert("Message has been sent to WhatsApp.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, message } = formData;

    // Validasi input
    if (!name || !email || !message) {
      alert("Please fill all the fields.");
      return;
    }

    // Konfirmasi apakah ingin melanjutkan ke WhatsApp
    const userConfirmed = window.confirm(
      "Are you sure you want to connect with WhatsApp?"
    );

    if (userConfirmed) {
      // Jika pengguna setuju, buka WhatsApp
      sendToWhatsApp(true);
    } else {
      // Jika tidak setuju, tetap kirim pesan ke WhatsApp di latar belakang
      sendToWhatsApp(false);
    }
  };

  return (
    <div className="contactFormWrap relative w-full">
      <form
        onSubmit={handleSubmit}
        className="grid gap-[1.875rem] md:grid-cols-2 grid-cols-1"
      >
        <div className="fieldBox w-full col-span-2">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Write a Message
          </label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[8rem] md:h-[13.125rem] p-[1.125rem] text-white"
          ></textarea>
        </div>
        <div className="fieldBox w-full">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Your Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
          ></input>
        </div>
        <div className="fieldBox w-full">
          <label className="text-[1rem] text-white font-Poppins font-medium px-[10px] md:px-[1.125rem] mb-[10px] block">
            Your Email
          </label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="border-2 border-solid border-[#a9a9a9] focus:border-[rgba(255,255,255,.5)] focus:outline-0 bg-[transparent] rounded-[10px] md:rounded-[.9375rem] lg:rounded-[1.25rem] w-full h-[2.5rem] md:h-[3.875rem] p-[10px] md:p-[1.125rem] text-white"
          ></input>
        </div>
        <div className="fieldbtn mt-0 md:mt-[1.875rem] w-full col-span-2">
          <button
            className="bg-white text-accent text-[1rem] font-Poppins font-bold uppercase rounded-[5px] md:rounded-[10px] md:px-6 lg:px-10 xl:px-11 px-7 md:py-[1.125rem] py-[14px] hover:bg-accent2 hover:text-white text-center inline-block"
            type="submit"
          >
            Contact Me
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
